import React from "react";
import "./base.css";
import Header from "./header";

class Template extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <>
        <Header />
        {children}
      </>
    );
  }
}

export default Template;
